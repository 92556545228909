import axios from '@/libs/axios';
import HelperService from '@/services/HelperService';

const baseUrl = '/admin/bills';

export default {
  getUnbilledEnterprises() {
    return axios.get(`${baseUrl}/unbilled`);
  },

  createBill(enterpriseUid) {
    return axios.post(`${baseUrl}`, enterpriseUid)
  },

  getBills(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },

  getBill(billId) {
    return axios.get(`${baseUrl}/${billId}`)
  },

  deleteBill(billId) {
    return axios.delete(`${baseUrl}/${billId}`)
  },
}
