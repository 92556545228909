<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="enterprises-page">
    <b-card class="" title="View Bills" ref="refreshCard">
      <b-card-text>This page shows a list of all enterprise bills</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <section>
        <div class="flex-row p-2">

          <b-input-group-append class="d-inline-flex w-50">
            <model-list-select v-model="filters.enterprise_uid"
                               :list="enterpriseOptions"
                               option-value="uid"
                               option-text="display_name"
                               placeholder="Select Enterprise"
                               @input="getBills"
            />
            <b-button @click="clearEnterpriseFilter" class="ml-1">Clear</b-button>
          </b-input-group-append>
        </div>
        <b-table
          class="data-table"
          hover
          :items="bills"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(enterprise)="data">
            <router-link :to="{ name: 'enterprise-view', params: { uid: data.item.enterprise.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.enterprise.display_name}}
            </router-link>
          </template>

          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>

          <template #cell(amount)="data">
            {{data.item.amount | intToCurrency}}
          </template>

          <template #cell(user)="data">
            <router-link :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.user.name}}
            </router-link>
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="viewBillPage(data.item.id)">
                View Bill
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showBillModal(data.item, 'modal-delete-bill')">
                Delete Bill
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreBills" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-bill" title="Delete Bill" hide-footer>
      <delete-modal
        v-if="selectedBill"
        @close="closeModals()" @delete="deleteBill(selectedBill)"
        :subtitle="selectedBill.enterprise_display_name"
        title="Are you sure you wish to delete this bill?"></delete-modal>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import BillService from '@/services/BillService';
import EnterpriseService from '@/services/EnterpriseService';
import helperService from '@/services/HelperService';
import { ModelListSelect } from 'vue-search-select';
import DeleteModal from '../../components/modals/DeleteModal.vue';

export default {
  name: 'ViewBills',
  components: {
    ModelListSelect,
    DeleteModal,
  },
  data() {
    return {
      headers: [
        {
          key: 'enterprise',
          label: 'Enterprise',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'user',
          label: 'Created By',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      bills: [],
      enterpriseOptions: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: '',
      },
      total_count: 0,
      selectedBill: {},
    };
  },
  mounted() {
    this.getBills();
    this.getAllEnterprises()
  },
  methods: {
    async getAllEnterprises() {
      this.filters.page_size = 10000
      try {
        const res = await EnterpriseService.getEnterprises()
        const enterprises = res.data.data
        enterprises.forEach(val => {
          this.enterpriseOptions.push(val)
        })
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get enterprises, please refresh and try again')
      }
    },
    clearEnterpriseFilter() {
      this.filters.enterprise_uid = ''
      this.getBills()
    },
    getMoreBills(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getBills();
    },
    async getBills() {
      try {
        this.$refs.refreshCard.showLoading = true
        const res = await BillService.getBills(this.filters)
        this.bills = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get bills, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false
        }, 100)
      }
    },
    async deleteBill() {
      try {
        await BillService.deleteBill(this.selectedBill.id)
        helperService.showNotfySuccess(this.$toast, 'Bill successfully Deleted')

        this.closeModals()
        this.selectedBill = {}
        await this.getBills()
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete bill, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showBillModal(bill, modalName) {
      this.setSelectedBill(bill);
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-bill');
    },
    setSelectedBill(bill) {
      this.selectedBill = bill;
    },
    viewBillPage(billId) {
      this.$router.push({ name: 'bill-details', params: { id: billId } })
    },
  },
};
</script>
